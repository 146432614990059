
@use 'sass:math';


$green: #2ecc71;
$dark-green: #27ae60;
$white: #e0e8eb;
$black: #2c3e50;
$red: #e74c3c;
$blue: #3498db;
$yellow: #f1c40f;
$orange: #f39c12;
$gray: #bdc3c7;
$dark-gray: #7f8c8d;


$vw: 1vw;
$px: 1px;

@function make-unitless($value){
    @if (type-of($value) != number) {@return $value;}

    @if (unit($value) == px) {@return math.div($value, 1px);}
    @if (unit($value) == vw) {@return math.div($value, 1vw);}
    @if (unit($value) == vh) {@return math.div($value, 1vh);}
    @if (unit($value) == em) {@return math.div($value, 1em);}
    @if (unit($value) == "%") {@return math.div($value, 1%);}

    @return $value;
}
@function px-to-vw($px, $ratio){
    @return math.div($px*100, $ratio) * $vw;
}
@function vw-to-px($vw, $ratio){
    @return math.div($vw*$ratio, 100) * $px;
}
@function ratio-from-px($_px, $ratio){
    @return min(make-unitless($_px)*$px, px-to-vw(make-unitless($_px), $ratio));
}
@function ratio-from-vw($_vw, $ratio){
    @return min(make-unitless($_vw)*$vw, vw-to-px(make-unitless($_vw), $ratio));
}

$page-max-width: 500;
$page-width: 100;
$page-ratio: math.div($page-max-width,  math.div($page-width, 100));

@function ratio-from($_px){
    @if (unit($_px) == px) {@return ratio-from-px($_px, $page-ratio);}
    @if (unit($_px) == vw) {@return ratio-from-vw($_px, $page-ratio);}
    // exeption
    @return $error "unit not supported";
}


#map-loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

#map-container{
    position: relative;

    width: 100%;
    height: 100px;

    overflow-x: hidden;
    overflow-y: hidden;
}

#map{
    transform-origin: 0px 0px;
    width: 740px;
    height: 500px;
}



html, body{
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

*{
    box-sizing: border-box;
    font-size: ratio-from(16px);
}

header{
    background-color: $black;
    color: $white;
    height: ratio-from(50px);

    display: flex;
    justify-content: center;
    align-items: center;
}

h1{
    font-size: ratio-from(24px);
}
h1 *{
    font-size:  ratio-from(24px);
}

footer{
    background-color: $white;
    color: $black;
    height: ratio-from(200px);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 ratio-from(20px);
    gap: ratio-from(10px);

    // top shadow
    box-shadow: 0px ratio-from(0px) ratio-from(20px) ratio-from(10px) #70707085;

    position: relative;
    z-index: 1;
}

.btn{
    padding: ratio-from(5px);
    font-size: ratio-from(16px);
    border-radius: ratio-from(5px);
    text-decoration: none;
    border: none;
    background-color: $blue;
    color: $white;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.btn.off{
    background-color: $dark-gray;
}
.btn.on{
    background-color: $blue;
}


.material-symbols-outlined{
    font-size: ratio-from(20px) !important;
}



#marker{
    width: 20px;
    height: 20px;
}
#marker-img{

    width: 20px;
    height: 20px;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {transform: scale(1);}
    50% {transform: scale(1.2);}
    100% {transform: scale(1);}
}

#map-btns{
    // top right map corner
    position: absolute;
    top: ratio-from(60px);
    right: ratio-from(20px);
    z-index: 1000;

    background-color: $white;
    // border: ratio-from(2px) solid $blue;
    padding: ratio-from(10px);

    display: flex;
    flex-direction: column;
    gap: ratio-from(10px);

    width: ratio-from(130px);

    box-shadow: 0px ratio-from(0px) ratio-from(20px) ratio-from(10px) #70707085;

}

.line{
    display: flex;
    flex-direction: row;
    gap: ratio-from(20px);
    justify-content: flex-start;
    align-items: center;
}